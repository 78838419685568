<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <!-- Receive list -->
        <material-card
          :title="$t('voucherReconciliation.list')"
          color="green"
          flat
          full-width
        >
          <tabHeader
            ref="tabHeader"
            :tab-items="mainTabItems"
            :selected-index="isSupplier() ? 0 : 1"
            @onSelectTab="onChangedTab" />
          <div @mouseleave="$refs.tblReconciliationList.expanded=[]">
            <v-data-table
              ref="tblReconciliationList"
              :headers="voucherInfoHeaders"
              :items="voucherInfoList"
              :no-data-text="$t('common.noDataAvailable')"
              :expand="false"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template v-slot:items="props">
                <tr
                  :class="props.item.isLoading ? 'bg-1' : ''"
                  style="position: relative;"
                  @click="props.expanded=!props.expanded"
                  @mouseover="props.expanded=true">
                  <td
                    v-for="(header, index) in voucherInfoHeaders"
                    :key="header.value + index">
                    <span v-if="header.value == 'checkCode'">
                      <span v-if="!props.item.isLoading">{{ props.item[header.value] }}</span>
                      <div
                        v-else
                        class="text-xs-center">
                        <v-progress-circular
                          :value="40"
                          indeterminate
                          class="text-xs-center"
                          color="white"
                        />
                      </div>
                    </span>
                    <span v-else-if="header.value == 'totalPrice'">
                      {{ formatMoney(props.item[header.value]) }}
                    </span>
                    <span
                      v-else-if="header.value == 'status'"
                      :style="`color: ${getColor(props.item[header.value])};`">
                      {{ $t(getStatus(props.item[header.value])) }}
                    </span>
                    <span v-else>{{ props.item[header.value] }}</span>
                  </td>
                </tr>
              </template>
              <template v-slot:expand="props">
                <div
                  class="py-2 mx-3"
                  style="display: flex; white-space: nowrap; padding-left: 60px"
                  @mouseleave="props.expanded=false">
                  <action-item
                    first
                    class="text-success"
                    @click="viewDetail(props.item)">
                    {{ $t('voucherReconciliation.action.view') }}
                  </action-item>
                  <action-item
                    v-if="props.item.status == VoucherReconciliationType.NOT_SUBMIT_YET"
                    class="red--text"
                    @click="onShowModalConfirmDelete(props.item.id)">
                    {{ $t('voucherReconciliation.action.delete') }}
                  </action-item>
                </div>
              </template>
            </v-data-table>
          </div>
        </material-card>
        <pagination
          :total="paginate.totalPage"
          :current-page="paginate.currentPage"
          :row-per-page="paginate.rowPerPage"
          @onPageChange="onPageChange"
        />
      </v-flex>
    </v-layout>
    <loading-bar :is-loading="isLoading" />
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmDeleteCheckVoucher"
    />
    <eVoucherReconciliationDetailModal
      ref="eVoucherReconciliationDetailModal"
      @sendCheckSuccess="onSendCheckSuccess" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ConfirmModal from 'Components/ConfirmModal'
import dateUtils from 'utils/dateUtils'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import EntityType from 'enum/entityType'
import VoucherReconciliationStatus from 'enum/voucherReconciliationStatus'
import VoucherReconciliationType from 'enum/voucherReconciliationType'
import EVoucherReconciliationDetailModal from '../Modal/EVoucherReconciliationDetailModal.vue'
import TabHeader from 'Components/TabHeader'
import ActionItem from 'Components/ActionItem.vue'
export default {
  components: {
    EVoucherReconciliationDetailModal,
    ConfirmModal,
    TabHeader,
    ActionItem
  },
  data: () => ({
    VoucherReconciliationType: VoucherReconciliationType,
    mainTabSelected: VoucherReconciliationType.NOT_SUBMIT_YET,
    mainTabItems: [
      {
        title: 'voucherReconciliation.tab.waitForReview',
        id: VoucherReconciliationType.WAIT_FOR_REVIEW
      },
      {
        title: 'voucherReconciliation.tab.notSubmitYet',
        id: VoucherReconciliationType.NOT_SUBMIT_YET,
        hidden: functionUtils.isEntityType(EntityType.SUPPLIER)
      },
      {
        title: 'voucherReconciliation.tab.reviewed',
        id: VoucherReconciliationType.APPROVED
      }
    ],
    voucherInfoHeaders: [
      { text: 'common.nonumber', value: 'stt' },
      { text: 'voucherReconciliation.code', value: 'checkCode' },
      { text: 'voucherReconciliation.createdDate', value: 'createdDate' },
      { text: 'voucherReconciliation.submitDate', value: 'submitDate' },
      { text: 'voucherReconciliation.totalVoucher', value: 'totalVoucher' },
      { text: 'voucherReconciliation.voucherApproved', value: 'totalVoucherReconciliationed' },
      // { text: 'voucherReconciliation.voucherWaitForApprove', value: 'totalVoucherWaitForApproval' },
      { text: 'voucherReconciliation.voucherCanceled', value: 'totalVoucherCanceled' },
      { text: 'voucherReconciliation.totalPrice', value: 'totalPrice' }
      // { text: 'voucherReconciliation.status.title', value: 'status' }
    ],
    voucherInfoList: [],
    paginate: {
      totalPage: 1,
      currentPage: 1,
      rowPerPage: 1
    },
    isLoading: false,
    selected: [],
    confirmModalTitle: '',
    btnCheckVoucherLoading: false,
    search: {
      voucherSerial: null
    },
    checkVoucherId: null
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHERS_RECONCILIATION_DATA'
    ])
  },
  watch: {
    VOUCHERS_RECONCILIATION_DATA () {
      let res = this.VOUCHERS_RECONCILIATION_DATA
      //   Handle paginate
      this.paginate.currentPage = res.paginate.currentPage
      this.paginate.totalPage = res.paginate.totalPage
      this.paginate.rowPerPage = res.paginate.perPage / 2
      //   Handle response data
      let voucherData = res.results
      this.voucherInfoList = []
      for (let i = 0, size = voucherData.length; i < size; i++) {
        let obj = {
          stt: i + 1 + (res.paginate.currentPage - 1) * res.paginate.pageSize,
          id: voucherData[i].id,
          checkCode: voucherData[i].code,
          createdDate: dateUtils.formatBeautyDate(voucherData[i].date_created),
          submitDate: dateUtils.formatBeautyDate(voucherData[i].submit_date),
          totalVoucher: voucherData[i].total_voucher,
          totalPrice: voucherData[i].total_price,
          status: voucherData[i].status,
          comment: voucherData[i].comment,
          supplierId: voucherData[i].supplier_id,
          totalVoucherReconciliationed: voucherData[i].total_voucher_reconciled,
          totalVoucherWaitForApproval: voucherData[i].total_voucher_wait_for_approval,
          totalVoucherCanceled: voucherData[i].total_voucher_canceled
        }
        this.voucherInfoList.push(obj)
      }
    }
  },
  created () {
    if (functionUtils.isEntityType(EntityType.SUPPLIER)) {
      this.mainTabSelected = VoucherReconciliationType.WAIT_FOR_REVIEW
    }
    this.getVouchersReconciliation()
  },
  methods: {
    onChangedTab: function ({ index, item }) {
      if (this.mainTabSelected !== item.id) {
        this.mainTabSelected = item.id
        if (this.mainTabSelected === VoucherReconciliationType.APPROVED) {
          this.voucherInfoHeaders.push({ text: 'voucherReconciliation.comment', value: 'comment' })
        } else {
          this.voucherInfoHeaders = this.voucherInfoHeaders.filter((element) => {
            return element.value !== 'comment'
          })
        }
        this.getVouchersReconciliation()
      }
    },
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    /**
     * Send check success
     */
    onSendCheckSuccess: function () {
      this.getVouchersReconciliation()
    },
    /**
     * View detail
     */
    viewDetail: function (item) {
      this.$refs.eVoucherReconciliationDetailModal.onShowModal(item)
    },
    /**
     * Get color
     */
    getColor: function (status) {
      if (VoucherReconciliationStatus.NOT_REQUEST_YET === status) {
        return 'blue'
      } else if (VoucherReconciliationStatus.PREPARE_FOR_SUBMIT === status) {
        return 'blue'
      } else if (VoucherReconciliationStatus.WAIT_FOR_APPROVAL === status) {
        return 'green'
      } else if (VoucherReconciliationStatus.APPROVED === status) {
        return 'green'
      } else {
        return 'red'
      }
    },
    /**
     * Get status text
     */
    getStatus: function (status) {
      return functionUtils.getReconciliationStatusText(status)
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      return functionUtils.convertFormatNumberToMoney(price)
    },
    /**
     * Confirm create voucher check
     */
    onConfirmDeleteCheckVoucher: function () {
      this.DELETE_RECONCILIATION_REQUEST({ id: this.checkVoucherId }).then(
        function () {
          this.ON_SHOW_TOAST({
            'message': this.$t('common.success'),
            'styleType': ToastType.SUCCESS
          })
          this.getVouchersReconciliation()
        }.bind(this)
      )
    },
    /**
     * Create voucher check
     */
    onShowModalConfirmDelete: function (id) {
      this.confirmModalTitle = 'Bạn có chắc chắn muốn xoá đối soát này?'
      this.checkVoucherId = id
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Page change
     */
    onPageChange: function (page) {
      this.paginate.currentPage = page
      this.getVouchersReconciliation()
    },
    /**
     * Get vouchers usage
     */
    getVouchersReconciliation: function () {
      let reconcilationType = this.mainTabSelected
      let filter = {
        params: {
          page: this.paginate.currentPage,
          reconcilationType: reconcilationType
        }
      }
      this.isLoading = true
      this.GET_VOUCHERS_RECONCILIATION(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    ...mapActions([
      'GET_VOUCHERS_RECONCILIATION',
      'ON_SHOW_TOAST',
      'DELETE_RECONCILIATION_REQUEST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.bg-1 {
  animation: myBg 5s infinite;
}
@keyframes myBg {
  from {background-color: #E8F5E9;}
  to {background-color: #4CAF50;}
}
@media (min-width: 960px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
  }
}
</style>
